.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 1.4rem 2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: var(--color-bg-variant-light);
  border-color: var(--color-bg-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-cream);
}

.experience__content {
  display: grid;
  grid-template-columns: 55% 40%;
  gap: 1%;
  row-gap: 1.4rem;
}

.experience__details {
  display: flex;
  gap: 0.5rem;
}
.experience__details__icon {
  margin-top: 6px;
  color: var(--color-green);
}

.experience__details small {
  font-style: italic;
}

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  .experience__content {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }
  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}
