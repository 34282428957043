.services_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.service__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-cream);

  background: var(--color-bg-variant);
  padding: 1.4rem 2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.service {
  background: var(--color-bg-variant);
  border-radius: 2rem 2rem 2rem 2rem;
  border: 1px solid transparent;
  height: fit-content;
  transition: var(--transition);
}
.service:hover {
  background: var(--color-bg-variant-light);
  border-color: var(--color-bg-variant);
  cursor: default;
}
.service__head {
  background: var(--color-bg-variant);
  padding: 2rem;

  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service h3 {
  color: var(--color-cream);
  font-size: 1rem;
  margin-top: 2rem;
  text-align: center;
}

.service__list {
  padding: 2rem;
}
.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}
.service__list__icon {
  color: var(--color-green);
  margin-top: 2px;
}
.service__list p {
  font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
  .services_container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .service {
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .services_container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
