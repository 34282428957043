@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: rgba(160, 160, 160, 0.8);
  --color-bg-variant: rgba(80, 81, 110, 0.8);
  --color-bg-variant-light: rgba(80, 81, 110, 0.4);
  --color-primary: rgba(245, 129, 42, 0.7);
  --color-primary-variant: hsla(205, 100%, 65%, 0.4);
  --color-white: #fff;
  --color-black: #000;
  --color-light: rgba(255, 255, 255, 0.8);
  --color-cream: rgba(235, 224, 214, 0.5);
  --color-green: rgba(0, 255, 0, 1);
  --color-orange: rgba(245, 129, 42, 1);

  --transition: all 400ms ease;
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Noto Sans", sans-serif;
  background: var(--color-bg);
  color: var(--color-light);
  line-height: 1.7;
  background-image: linear-gradient(
      var(--color-black),
      var(--color-black) 2%,
      var(--color-light) 12%,
      var(--color-bg-variant-light) 50%,
      var(--color-light) 62%,
      var(--color-black) 100%
    ),
    url("./assets/AI-Background.jpg");
}

#wrapper {
  min-height: calc(100vh - 8rem);
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 6rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-light);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}
.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-light);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

::placeholder {
  color: var(--color-bg);
}

/* ================== Media Queries   */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-top: 2rem;
  }
}
