footer {
  position: relative;
  padding: 1rem 0;
  margin-top: 3rem;
  height: 4rem;
  overflow: hidden;
}

.footer__container {
  display: grid;
  grid-template-columns: 1.2fr 0.6fr 1.2fr;
  gap: 1rem;
}

.footer__image {
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 1.8rem;
  overflow: hidden;
}

.footer__col {
  position: relative;
  text-align: center;
}

.footer__content {
  background-color: blue;
  position: absolute;
  left: 50%;
  bottom: em;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

footer a {
  color: var(--color-light);
}

.footer__logo {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0rem;
}
.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto 0rem;
}

.footer__copyright {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.4rem;
  color: var(--color-light);
}

@media screen and (max-width: 1024px) {
  .permalinks {
    gap: 0.3rem;
  }
  .footer__copyright {
    gap: 0.1rem;
  }
  footer {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 600px) {
  footer {
    margin-top: 0rem;
  }
  .permalinks {
    gap: 0.1rem;
  }
  .footer__container {
    grid-template-columns: 2fr 0fr 2fr;
    gap: 0rem;
  }
  .footer__copyright {
    padding-top: 0.7rem;
    font-size: x-small;
  }
}
