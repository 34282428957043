.nopage__container {
  background: var(--color-bg-variant);
  padding: 2.4rem 2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
}

.nopage__container h3 {
  text-align: center;
  margin: rem auto 0 1.5rem;
  color: red;
}
