.about__container {
  display: grid;
  grid-template-columns: 30% 55%;
  gap: 10%;
}
.about__container__footer {
  display: grid;
  grid-template-columns: 70% 15%;
  gap: 2%;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 1rem;
  /*background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );*/
  display: grid;
  place-items: center;
}

.about__me__image {
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 5rem;
}

.about__cards {
  margin: 1rem auto 1rem auto;
  display: grid;
  grid-template-areas: "a a a";
  gap: 1rem;
  grid-auto-columns: 8rem;
}
.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  height: 10rem;
  text-align: center;
  transition: var(--transition);
}
.about__card:hover {
  background: var(--color-bg-variant-light);
  border-color: var(--color-bg-variant);
  cursor: default;
}
.about__icon {
  color: var(--color-light);
  font: size 1.4rem;
  margin-bottom: 1rem;
}

.about__card small {
  font-size: 0.6rem;
  color: var(--color-light);
}

.about__cards {
  margin-bottom: 2rem;
}
.about__content p {
  margin: 1rem 0 1rem;
  color: var(--color-black);
  font-size: 0.9rem;
  font-weight: 300;
}

.about__content {
  user-select: none;
}

.about__content h3 {
  font-weight: 400;
  color: var(--color-black);
  margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }
  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }
  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .about__content {
    text-align: center;
  }
  .about__content p {
    margin: 1.5rem 0;
  }
  .about__cards {
    display: none;
  }
}
