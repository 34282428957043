.imprint__container {
  background: var(--color-bg-variant);
  padding: 2.4rem 2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
}

.imprint__container p {
  margin: 0 auto 0 1.5rem;
}

.imprint__container a {
  color: var(--color-orange);
}
